<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.entId" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option class="filter-item" @change="getStatementType" style="width: 180px;" />
      <quick-select v-if="statementType==='serta'" v-model="query.buyerErpId" filterable url="api/distributorErps/list" placeholder="下单账户" value-field="erpId" display-field="name" @change="reQuery" class="filter-item"  style="width: 180px;" autoSelectFirstOption/>
      <el-date-picker v-if="statementType==='serta'" v-model="query.yearAndMonth" type="month" :clearable="false" value-format="yyyy-M" placeholder="选择会计期间" @change="reQuery" style="width: 160px;" />
      <div v-else>
        <el-date-picker v-model="query.dateRange" type="daterange" :clearable="false" class="filter-item" unlink-panels range-separator="至" start-placeholder="记录日期起始" end-placeholder="记录日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 240px;" />
        <el-select v-model="query.recordType" filterable clearable placeholder="操作" @change="reQuery" style="width: 120px; margin-right: 6px;">
            <el-option v-for="(v, i) in recordTypes" :key="i" :label="v" :value="i" />
        </el-select>
        <el-select v-model="query.relationFormType" filterable clearable placeholder="单据类型" @change="reQuery" style="width: 120px; margin-right: 6px;">
            <el-option v-for="(v, i) in relationFormType" :key="i" :label="v" :value="i" />
        </el-select>
      </div>      
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="reQuery">刷新</el-button>
    </div>
    <el-card shadow="never" v-if="count">
      <div class="h" v-if="statementType==='serta'">
        <div class="money-count-item">
          <div class="fc-g">账户余额</div>
          <counter class="num" :class="count.moneyBalance > 0 ? 'fc-e' : ''" :end-val="count.moneyBalance / 100" :decimals="2" :duration="1000" />
        </div>
      </div>
      <div class="h" v-else>
        <div class="money-count-item">
          <div class="fc-g">累计应付</div>
          <counter class="num" :class="count.balance > 0 ? 'fc-e' : ''" :end-val="count.balance / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">账户余额</div>
          <counter class="num" :class="count.moneyBalance < 0 ? 'fc-e' : ''" :end-val="count.moneyBalance / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">返利余额</div>
          <counter class="num" :class="count.rebateBalance < 0 ? 'fc-e' : ''" :end-val="count.rebateBalance / 100" :decimals="2" :duration="1000" />
        </div>
      </div>
    </el-card>
    <el-table key="serta" v-loading="loading" row-key="id" :data="data" highlight-current-row empty-text="未找到对账信息" height="100px" v-if="statementType==='serta'">
      <el-table-column label="单据日期" width="140" :formatter="r => { return r.recordTime?new Date(r.recordTime).format():''; }" fixed />
      <!-- <el-table-column prop="recordType" label="操作" width="90" sortable="custom" fixed>
        <template slot-scope="scope">{{recordTypes[scope.row.recordType || 0]}}</template>
      </el-table-column>
       <el-table-column prop="relationFormType" label="单据类型" width="100" sortable="custom" fixed>
        <template slot-scope="scope">{{relationFormType[scope.row.relationFormType]}}</template>
      </el-table-column>-->      
      <el-table-column prop="recordTypeStr" label="单据类型" width="140" />
      <el-table-column prop="relationFormCode" label="单据编号" width="150" />
      <el-table-column prop="info" label="备注" min-width="180" show-overflow-tooltip />
      <el-table-column prop="payable" label="本期应收" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="payMoney" label="本期实收" min-width="110" align="right" :formatter="$price" />
      <el-table-column prop="balance" label="期末余额" min-width="120" align="right" :formatter="$price" />
    </el-table>

    <el-table key="kingkoil" v-loading="loading" row-key="id" :data="data" highlight-current-row empty-text="未找到对账信息" height="100px" v-else-if="statementType">
      <el-table-column label="单据日期" width="140" :formatter="r => { return r.recordTime?new Date(r.recordTime).format():''; }" fixed />
      <el-table-column prop="recordType" label="操作" width="80" sortable="custom" fixed>
        <template slot-scope="scope">{{recordTypes[scope.row.recordType || 0]}}</template>
      </el-table-column>
      <el-table-column prop="relationFormCode" label="单据编号" width="140" />
      <el-table-column prop="info" label="备注" min-width="200" show-overflow-tooltip />
      <el-table-column label="本期应收" min-width="120" align="right" :formatter="r=>{return $price(r.payable + r.rebate);}" />
      <el-table-column prop="payable" label="应收现金" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="payMoney" label="实收现金" min-width="110" align="right" :formatter="$price"/>
      <el-table-column prop="rebate" label="使用返利金" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="payAccountMoney" label="使用余额" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="balance" label="期末应收" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="rebateBalance" label="返利余额" min-width="120" align="right" :formatter="$price" />
      <el-table-column prop="moneyBalance" label="账户余额" min-width="120" align="right" :formatter="$price" />
    </el-table>
    <el-pagination v-if="statementType!=='serta'" :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { getBySupplier, getStatementTypeBySupplier } from "@/api/capitalPool";
import Counter from "vue-count-to";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  components: { Counter },
  data() {
    return {
      downloadLoading: false,
      count: null,
      query: {
        entId: null,
        yearAndMonth: new Date(),
        dateRange: [
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          new Date().getTime(),
        ],
      },
      statementType: null,
      recordTypes: [
        "采购下单",
        "采购付款",
        "退货退款",
        "充值",
        "提现",
        "返利入账",
        "返利出账",
        "费用单下单",
        "费用单付款",
        "其它",
      ],
      relationFormType: {
        order: "销售单",
        purchase: "采购单",
        orderBack: "退单",
        payForm: "收退款单",
        rebateApply: "返利申请单",
        bill: "费用单",
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/capitalPool/buyer";
      let query = JSON.parse(JSON.stringify(this.query));
      let required = false;
      if (query.entId) {
        if (query.dateRange && query.dateRange.length === 2) {
          required = true;
          query.begTime = query.dateRange[0];
          query.endTime = query.dateRange[1];
        }
        delete query.dateRange;
      }
      if (query.entId && query.yearAndMonth) {
        required = true;
      }
      if (required) {
        this.params = query;
        this.params.sort = "recordTime,asc";
      } else {
        this.$message.info("请选择供应商和记录日期范围进行搜索。");
      }
      return required;
    },
    calcCount() {
      if (this.params) {
        getBySupplier(this.params.entId, this.query.buyerErpId).then((res) => {
          this.count = res;
        });
      }
    },
    getStatementType() {
      if (this.query) {
        getStatementTypeBySupplier(this.query.entId).then((res) => {
          this.statementType = res;
          this.reQuery();
        });
      }
    },
    reQuery() {
      if (this.statementType === "serta" && !this.query.buyerErpId) return;
      this.current = null;
      this.toQuery();
      this.calcCount();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/capitalPool/download", this.params)
        .then((result) => {
          downloadFile(result, "采购对账", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    let now = new Date();
    this.query.dateRange = [
      new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate(),
        0,
        0,
        0
      ).getTime(),
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      ).getTime(),
    ];
  },
};
</script>